import { Text } from '@/components/Typography'
import { IconInfoCircle } from '@tabler/icons-react'
import { type ComponentProps, forwardRef } from 'react'
import {
  Input,
  InputError,
  Spacer,
  TextFieldContainer,
  TextFieldContent,
} from './styles'

export type TextFieldProps = ComponentProps<typeof Input> & {
  label: string
  error?: string
  className?: string
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, error, className, ...props }, ref) => {
    return (
      <TextFieldContainer className={className}>
        <TextFieldContent hasError={!!error}>
          <Text as="label" htmlFor={label} size="caption1">
            {label}
          </Text>
          <Input id={label} type="text" {...props} ref={ref} />
        </TextFieldContent>
        {error ? (
          <InputError>
            <IconInfoCircle />
            <Text size="caption1">{error}</Text>
          </InputError>
        ) : (
          <Spacer />
        )}
      </TextFieldContainer>
    )
  },
)

TextField.displayName = 'TextField'

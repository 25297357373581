import { styled } from '@/styles/theme'
import type { ComponentProps } from 'react'

export const LinkButton = styled('button', {
  width: 'fit-content',
  height: 'fit-content',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '$base',
  fontWeight: '$bold',
  cursor: 'pointer',
  lineHeight: '$lg',

  position: 'relative',
  transition: 'all 0.2s',

  '&::before': {
    content: '',
    position: 'absolute',
    width: '100%',
    borderBottom: '2px solid $secondary50',
    bottom: 0,
    left: 0,
    right: 0,
    transition: '0.2s',
  },

  '&:not(:disabled):hover::before': {
    width: '65%',
  },

  variants: {
    variant: {
      default: {
        color: '$secondary50',

        '&::before': {
          borderBottom: '2px solid $secondary50',
        },

        '&:focus': {
          outline: '4px solid $secondary90',
          borderRadius: '$xs',
        },

        '&:disabled': {
          cursor: 'auto',
          color: '$neutral70',
        },
      },

      neutral: {
        color: '$neutral40',

        '&::before': {
          borderBottom: '2px solid $neutral40',
        },

        '&:focus': {
          outline: '4px solid $secondary90',
          borderRadius: '$xs',
        },

        '&:disabled': {
          cursor: 'auto',
          color: '$neutral80',
        },
      },
    },
  },

  defaultVariants: {
    variant: 'default',
  },
})

export type LinkButtonProps = ComponentProps<typeof LinkButton>

LinkButton.displayName = 'LinkButton'

import { styled } from '@/styles/theme'
import * as Dialog from '@radix-ui/react-dialog'
import { PrimaryButton } from '../Button/PrimaryButton'
import { Text } from '../Typography'

export const ModalRoot = styled(Dialog.Root, {})

export const ModalTrigger = styled(Dialog.Trigger, {})

export const ModalPortal = styled(Dialog.Portal, {})

export const ModalOverlay = styled(Dialog.Overlay, {
  backgroundColor: '$neutral20',
  opacity: 0.8,

  position: 'fixed',
  width: '100vw',
  height: '100vh',
  inset: '0',
})

export const ModalClose = styled(Dialog.Close, {})

export const ModalContent = styled(Dialog.Content, {
  position: 'fixed',
  inset: 0,
  margin: 'auto',
  minWidth: 312,
  Width: '312px',
  height: 'fit-content',
  minHeight: '280px',

  backgroundColor: '$white',
  display: 'grid',
  gridTemplateRows: 'repeat(3, 1fr)',
  gridTemplateColumns: '1fr',
  alignItems: 'start',
  justifyContent: 'center',
  borderRadius: '$sm',
  paddingBlockStart: '44px',

  '@md': {
    width: 645,
    maxHeight: 432,
  },

  '> header': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',

    [`${Text}`]: {
      color: '$error40',
      fontWeight: '$bold',
    },

    svg: {
      width: 72,
      height: 72,
    },
  },

  '>button:first-child': {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 24,
  },
})

export const ModalTitle = styled(Dialog.Title, {
  fontFamily: '$primary',
  color: '$neutral10',
  lineHeight: 1.2,
  fontSize: '1.5rem',
  fontWeight: '$bold',
  marginBlockEnd: '24px',
  textAlign: 'center',

  '@md': {
    fontSize: '2rem',
  },
})

export const ModalDescription = styled(Dialog.Description, {
  textAlign: 'center',
  color: '$neutral50',
  lineHeight: 1.5,
  textWrap: 'pretty',
})

export const ModalIcon = styled('div', {
  display: 'flex',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'transparent',
  borderRadius: '$circular',
  padding: 0,

  variants: {
    variant: {
      information: {},
      error: {
        backgroundColor: '$error50',
        color: '$error90',
      },
      success: {},
    },
  },
})

export const ModalFooter = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'flex-end',
  padding: '16px 24px',
  gap: '1rem',

  borderTop: '1px solid $neutral90',

  [`${PrimaryButton}`]: {
    alignSelf: 'end',
  },

  variants: {
    variant: {
      cancel: {
        '@md': {
          paddingInline: '112px',
        },
      },
    },
  },
})

import { Text } from '@/components/Typography'
import { styled } from '@/styles/theme'

export const TextFieldContainer = styled('div', {
  width: '100%',
  height: '100%',
})

export const TextFieldContent = styled('div', {
  width: '100%',
  minHeight: '60px',
  backgroundColor: '$white',
  border: '1px solid $neutral70',
  borderRadius: '$md',
  padding: '9px $2',
  transition: ':focus-within 0.2s',

  [`${Text}`]: {
    fontWeight: '$semibold',
    color: '$neutral50',

    div: {
      transition: 'all 0.15s ease-out',
    },
  },

  '&:not(:disabled):not(:focus-within):not(:read-only):hover': {
    border: '2px solid $neutral50',
    [`${Text}`]: {
      color: '$neutral30',
      fontWeight: '$semibold',
    },
  },

  '&:focus-within': {
    border: '2px solid $secondary70',
    [`${Text}`]: {
      color: '$neutral50',
      fontWeight: '$semibold',
    },
  },

  '&:invalid': {
    background: '$error90',
    border: '2px solid $error50',
    borderRadius: '$sm',
  },

  '&:disabled': {
    backgroundColor: '$neutral95',
    border: '1px solid $neutral80',
  },

  '&:has(input:read-only)': {
    backgroundColor: '$neutral95',
    color: '$neutral70',

    [`${Text}`]: {
      color: '$neutral70',
    },
  },

  variants: {
    hasError: {
      true: {
        background: '$error90',
        border: '2px solid $error50',
        borderRadius: '$sm',
      },
    },
  },
})

export const Input = styled('input', {
  all: 'unset',
  boxSizing: 'border-box',
  width: '100%',
  border: 'transparent',
  outline: 'none',
  backgroundColor: 'transparent',
  color: '$neutral30',
  fontFamily: '$secondary',
  fontSize: '$base',
  fontWeight: '$semibold',
  lineHeight: '$md',

  '&::placeholder': {
    color: '$neutral60',
    fontWeight: '$semibold',
    lineHeight: '$md',
  },

  '&:read-only': {
    color: '$neutral70',
  },
})

export const InputError = styled('div', {
  display: 'flex',
  alignItems: 'center',
  margin: '4px $2',
  gap: 6,

  svg: {
    width: '12px',
    height: '12px',
    color: '$error50',
  },
  [`${Text}`]: {
    color: '$neutral50',
    fontWeight: '$semibold',
  },
})

export const Spacer = styled('div', {
  height: 25.75,
})
